import React from 'react';
import PartnersCard from './util/PartnersCard';
import perfectChefLogo from "./images/LogoPerfectChef.png"
import TancloudLogo from "./images/TancloudLogo.jpg"
import OstourahLogo from './images/OstourahLogo.png'
import AmrosLogo from './images/AmrosLogo.png'

const Partners = () => {
 

  return (
    <div id="PartnersCards" className="max-w-[1400px] m-auto py-16 px-4">
    <div className="flex flex-col h-full justify-center m-20">
      <h3 className="text-center text-5xl md:text-6xl font-bold">Clients cases</h3>
    </div>
  
    <div className="grid grid-cols-1 gap-14 justify-items-center">
    
      <PartnersCard 
      imageSrc={perfectChefLogo}
      name="Perfect Chef"
      info="As dominant chocolate distributor and pastery equipment supplier in the region, Perfect Chef wanted to cover more products by offering food grade plastics for bakery. After examining the samples they sent to us we were able to export the  materials requested from China, saving 40% of the price offered from the local market. "
      country="KSA"
      color="warning"
      />
      <PartnersCard 
      imageSrc= {TancloudLogo}
      name="Tancloud For Future Innovations"
      info="Tancloud is a leading medical products distributor in east Africa region, and they have been missing a partner that will link them to the substantial Chinese medical equipment market. After reaching out we were able fulfil all the demands they needed and secured a multi-year bid."
      country="Tanzania"
      color="primary"
      />
      <PartnersCard 
      imageSrc={OstourahLogo}
      name="Ostourah Kitchen House"
      info="As a medium size firm specialised in kitchen designing and manufacturing, Ostourah requires a variety of kitchen accessories on monthly basis, thanks to our  warehouses in China we are able to purchase different products from different suppliers at our warehouse and ship them together in one container every month."
      country="KSA"
      color="success"
      />
      <PartnersCard 
      imageSrc={AmrosLogo}
      name="Amross For Investment"
      info="Amros is an investment company constantly looking for new trading opportunities, we have helped Amros with their project research and have cooperated on guiding the firm from coming up with idea to the actual execution. Together we have conducted large scale projects successfully importing the machinaries for the project from China."
      country="Sudan"
      color="warning"
      />
      
    </div>
  </div>

  );
};

export default Partners;

import React from 'react'

export default function ImageOverlay({src, text, alt,divClassName,text2}) {
  return (
      <div className={divClassName}>
          <img
              className='object-cover w-full h-full opacity-100'
              src={src}
              alt={alt}
          />

          <div className='absolute inset-0 flex justify-center items-center'>
          <p className='text-white text-center font-bold'>{text}<br/>{text2}</p>

          </div>

      </div>
  )
}

import React from 'react';
import CategoriesCard from './util/CategoriesCard';

export default function Categories() {
    return (
        <div id="ProductsCategories" className="max-w-[1400px] m-auto py-16 px-4">
            {/* Title  */}
            <div className="flex flex-col h-full justify-center items-center mb-20">
                <h3 className="text-5xl md:text-6xl font-bold">Product Categories</h3>
                
            </div>
         
 {/*FOOD PACKAGING AND PASTERY MATERIALS, CARS SPARE PARTS AND ACCESSORIES, MEDICAL PRODUCTS, AGRICULTURAL PROUDCTS, construction materials*/}
 
            {/* Cards */}
            <div className="flex justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 xs:gap-0">
                    <CategoriesCard 
                    img="https://images.unsplash.com/photo-1499889808931-317a0255c0e9?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    title= "DINING ESSENTIALS"
                    info= "Food packaging and pastery materials"
                    />
                    <CategoriesCard 
                    img="https://images.unsplash.com/photo-1610647752706-3bb12232b3ab?q=80&w=2825&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D "
                    title= "AUTOMOBILES PARTS"
                    info="Cars spare parts and accessories"
                    />
                    <CategoriesCard 
                    img="https://images.unsplash.com/photo-1600091474842-83bb9c05a723?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    title= "MEDICAL PRODUCTS"
                    info="Medical devices and disposables"
                    />
                    <CategoriesCard 
                    img="https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    title= "CONSTRUCTION MATERIALS"
                    info="Interior and exterial equipments"
                    />

                    {/* Add more CategoriesCard components here if needed */}
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import { FaFacebook,FaEnvelope,FaMapPin,FaPhoneAlt, FaWhatsapp,FaQrcode } from 'react-icons/fa';


const Footer = () => {
  return (
    <div id='ContactUs' className='w-full mt-24 bg-gray-900 text-gray-300 py-2 px-2 flex justify-between'>
    <div className='max-w-[1400px] mx-auto border-b-2 border-gray-600 py-8 px-4 flex justify-center flex-col md:flex-row   gap-20'>
        
        
        <div className='flex flex-col max-w-[1400px] px-2 py-4 mx-auto justify-between sm:flex-col text-center text-gray-500'>
        
        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
          
          
          <FaFacebook className='text-white  hover:text-gray-500 cursor-pointer' onClick={()=> window.open("https://www.facebook.com/profile.php?id=100037757172148&mibextid=ZbWKwL", '_blank')} />
          <FaWhatsapp className='text-white  hover:text-gray-500 cursor-pointer' onClick={()=> window.open("https://wa.me/8619860416030", '_blank')} />
          <FaQrcode className='text-white  hover:text-gray-500 cursor-pointer' onClick={()=> window.open("https://u.wechat.com/MDtiOD_e3iMTGBd3FH_vV2w", '_blank')} />
          
          
        </div>
        <div>
        <p className='py-4'>2024 Arzag Trading, LTD. All rights reserved</p>
        </div>
       
      </div>
      <div className='flex flex-row flex-col'>
            <h6 className='font-bold uppercase pt-2'>Get in touch:</h6>
            <ul className='flex flex-col gap-2'>
                <li className='py-1 flex items-center'>
                   
                    <FaPhoneAlt className='mr-2' />

                    <span>0086-198-60416030</span>
                </li>

                <li className='py-1 flex items-center'>
                    <FaEnvelope className='mr-2' />
                    <span>mohamed@arzagtrading.com</span>
                </li>
                <li className='py-1 flex items-center'>
                    <FaMapPin className='mr-2' />
                    <span>Office 623, No. 37, <br/> Fuli International Trade Center, Guangzhou,China</span>
                </li>

                
            </ul>
        </div>
        
      </div>

      
    </div>
  );
};

export default Footer;

{/* <div className='col-span-2 py-8 md:pt-2'>
          <p className='font-bold uppercase'>Subscribe to our newsletters</p>
          <p className='py-4'>
            The Latest deals, articles and resources sent to your inbox weekly.
          </p>
          <form className='flex flex-col sm:flex-row'>
            <input className='w-full p-2 mr-4 rounded-md mb-4' type='email' placeholder='Enter email..' />
            <button className='p-2 mb-4 rounded-md'>Subscribe</button>
          </form>
        </div> */}
import * as React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';


export default function CategoriesCard({img,title,info}) {
  return (
    <Card sx={{ minHeight: '500px', width: { xs: 280, md: 320 } }}>
      <CardCover>
        <img
          src={img}
          srcSet={img}
          loading="lazy"
          alt=""
        />
      </CardCover>
      <CardCover
        sx={{
          background:
            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 100px)',
        }}
      />
      <CardContent sx={{ justifyContent: 'flex-end' }}>
        <Typography level="title-lg" textColor="#fff">
          {title}
        </Typography>
        <Typography
         
          textColor="neutral.300"
        >
          {info}
        </Typography>
      </CardContent>
    </Card>
  );
}
import React from 'react';
import ImageOverlay from './util/ImageOverlay';

const Services = () => {
  return (
    <div id="TradingServices" className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>
      {/* Left Side */}

      <div className='grid grid-cols-2 grid-rows-6 h-[80vh]'>

        <ImageOverlay
          divClassName={'relative row-span-3 w-full h-full p-2 '}
          src={'https://images.pexels.com/photos/4481260/pexels-photo-4481260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2https://images.pexels.com/photos/4481534/pexels-photo-4481534.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'}
          text="Factory / Product verification"
          alt="image 1"
        />
  
{/*  FACTORY/PRODUCTS INSPECTION, FINDING SUPPLIER BASED ON SAMPLE, WAREHOUSES FOR COLLECTING GOODS FROM DIFFERENT SUPPLIERS,OEM, COSTS SAVINGS */}
  

        <ImageOverlay
          divClassName={'relative row-span-2 w-full h-full p-2'}
          src='https://images.pexels.com/photos/9594423/pexels-photo-9594423.jpeg'
          alt='Image 2'
          text="Finding Suppliers "
          text2= "Based On Sample"
         
        />

        <ImageOverlay
          divClassName={'relative row-span-2 w-full h-full p-2'}
          src='https://images.unsplash.com/photo-1609143739217-01b60dad1c67?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt='/'
          text="Collecting goods "
          text2= "from different suppliers"

        />

        <ImageOverlay
          divClassName={'relative row-span-3 w-full h-full p-2'}
          src='https://images.unsplash.com/photo-1651341508318-39601132a85e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt='/'
          text="OEM assistance "
          text2= "to personalize your products"
        />
       
      
       <ImageOverlay
          divClassName={'relative row-span-2 w-full h-full p-2'}
          src='https://images.unsplash.com/photo-1558318830-853081b6bb6e?q=80&w=2956&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt='/'
          text="Shipping service to all ports "
          text2= "around the world"

        />

       
      </div>
      {/* Right Side */}
      <div className='flex flex-col h-full justify-center' >
        <h3 className='text-5xl md:text-6xl font-bold'>Plan Your Next Order With Us</h3>
        <p className='text-2xl py-6'>
         
        </p>
        <p className='pb-6'>
          
      We offer comprehensive services for sourcing and logistics, including factory and product verification, finding suppliers based on samples, OEM assistance for product personalization, consolidation of goods from various suppliers, and shipping to ports worldwide.
        </p>
        <div>
          {/* <button className='border-black mr-4 hover:shadow-xl'>
            Learn More
          </button>
          <button className='bg-black text-white border-black hover:shadow-xl'>Avail Services</button>
          */}
        </div> 
      </div>
    </div>
  );
};

export default Services;

/* <div className='relative row-span-3 w-full h-full p-2'>
<img
    className='object-cover w-full h-full opacity-70'
    src='https://images.pexels.com/photos/7286654/pexels-photo-7286654.jpeg?auto=compress&cs=tinysrgb&w=600'
    alt='Image 1'
/>

<div className='absolute inset-0 flex justify-center items-center'>
    <p className='text-white text-center'>Your Text</p>
</div>


</div> */

/* <div className='grid grid-cols-2 grid-rows-6 h-[80vh]'>
        
        <img
          className='row-span-3 object-cover w-full h-full p-2'
          src='https://images.pexels.com/photos/7286654/pexels-photo-7286654.jpeg?auto=compress&cs=tinysrgb&w=600'
          alt='/'
        />

        <img
          className='row-span-2 object-cover w-full h-full p-2'
          src='https://images.pexels.com/photos/2468399/pexels-photo-2468399.jpeg?auto=compress&cs=tinysrgb&w=600'
          alt='/'
        />  */
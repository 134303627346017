import DropDown from './components/DropDown.jsx';
import Hero from './components/Hero.jsx';
import NavBar from './components/NavBar.jsx';
import Services from './components/Services.jsx';
import Agriculture from './components/Agriculture.jsx';
import Partners from './components/Partners.jsx'
import Footer from './components/Footer.jsx'
import Categories from './components/Categories.jsx';


function App() {
  return (
    <div>
      <DropDown />
      <Hero />
      <NavBar />
      <Services />
      <Categories/>
      <Agriculture />
      <Partners />
      <Footer />


    </div>
  );
}

export default App;


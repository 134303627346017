import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';

export default function PartnersCard({imageSrc, color,info,country}) {
  return (
    <Card orientation="vertical" variant="outlined" sx={{ width: { xs: 300, md: 900 }, height:{ xs: 500, md: 350 } , display: 'flex', alignItems: 'center'  }}>
      <CardOverflow sx={{display: 'flex', alignItems: 'center'}} >
      <AspectRatio ratio="16/11" sx={{ width: 230 ,marginY:4}}>
          <img
            src={imageSrc}
            srcSet={` ${imageSrc} 2x`}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent sx={{display: 'flex', alignItems: 'center'}} >
        <Typography fontWeight="md" fontSize="17px" textColor="success.plainColor">
          {/* {name} */}
        </Typography>
        <Typography fontSize="15.5px" level="body-sm" >{info}</Typography>
      </CardContent>
       <CardOverflow
        variant="soft"
        color={color}
        sx={{
          px: 0.2,
          textAlign:"center",
          justifyContent: 'center',
          fontSize: 'xs',
          fontWeight: 'xl',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderLeft: '1px solid',
          borderColor: 'divider',
        }}
      >
        {country}
      </CardOverflow> 
    </Card>
  );
}

import React, { useState } from 'react';
import { HiMenuAlt3 } from 'react-icons/hi';
import { Link as ScrollLink } from 'react-scroll';

const Dropdown = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
    if(!nav) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'scroll'
    }
  };

  return (
    <div className='absolute w-full flex justify-between p-4 items-center'>
      <div className=''>
        <img className="mb-2 mr-2 w-24 h-24 z-20 inline-block" src='../ArzagLogo.png' alt='loading'></img>
      
      </div>


      <HiMenuAlt3 onClick={handleNav} className='text-gray-100 cursor-pointer fixed top-10 right-4 z-50 block sm:hidden bg-gray-600 rounded-full p-2' size={40} />

      <div
        className={
          nav
            ? 'ease-in duration-300 fixed text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex-col z-10'
            : 'absolute top-0 h-screen left-[-100%] ease-in duration-500 z-10'
        }
      >
        <div onClick={handleNav} className='flex flex-col fixed w-full h-full  justify-center'>
        
          <ScrollLink onClick={handleNav} to="TradingServices" smooth={true} duration={900} className='text-left font-bold text-3xl p-8 smoothscroll hover:text-gray-500 cursor-pointer'>Trading services</ScrollLink>
          
          <ScrollLink onClick={handleNav} to="ProductsCategories" smooth={true} duration={900} className='font-bold text-3xl p-8 smoothscroll hover:text-gray-500 cursor-pointer'>Products categories</ScrollLink>
          
          <ScrollLink onClick={handleNav} to="ArzagAgriculture" smooth={true} duration={900} className='font-bold text-3xl p-8 smoothscroll hover:text-gray-500 cursor-pointer'>Agricultural trading</ScrollLink>
          
          <ScrollLink onClick={handleNav} to="ContactUs" smooth={true} duration={900} className='font-bold text-3xl p-8 smoothscroll hover:text-gray-500 cursor-pointer'>Contact us</ScrollLink>
       
        </div>
      </div>
    </div>
  );
};

export default Dropdown;

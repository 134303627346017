import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {
  return (
      <div className="text-black max-w-[1470px] sticky top-0 bg-white bg-opacity-75 z-50 hidden  sm:block   ">

        <div className="text-black max-w-[900px] m-auto px-4 py-4 flex flex-wrap justify-between">
          <ScrollLink to="TradingServices" smooth={true} duration={500} className="text-lg  smoothscroll hover:text-gray-500 cursor-pointer">Trading services</ScrollLink>
          
          <ScrollLink to="ProductsCategories" smooth={true} duration={500} className="text-lg  smoothscroll hover:text-gray-500 cursor-pointer">Products categories</ScrollLink>
          
          <ScrollLink to="ArzagAgriculture" smooth={true} duration={500} className="text-lg  smoothscroll hover:text-gray-500 cursor-pointer">Agricultural trading</ScrollLink>
          
          <ScrollLink to="ContactUs" smooth={true} duration={500} className="text-lg  smoothscroll hover:text-gray-500 cursor-pointer">Contact us</ScrollLink>
        </div>
      </div>
  );
};

export default Navbar;
// const Offers = () => {
//   return (
//     <div className=' text-black max-w-[900px] m-auto px-4 py-12 flex flex-wrap justify-between'>

//         <ScrollLink to="TradingServices" smooth={true} duration={500} className="text-lg font-bold smoothscroll hover:text-gray-500 cursor-pointer"> Trading services </ScrollLink>
//         {/*  FACTORY/PRODUCTS INSPECTION, FINDING SUPPLIER BASED ON SAMPLE, WAREHOUSES FOR COLLECTING GOODS FROM DIFFERENT SUPPLIERS,OEM, COSTS SAVINGS */}
  
//         <ScrollLink to="ProductsCategories" smooth={true} duration={500} className="text-lg font-bold smoothscroll hover:text-gray-500 cursor-pointer"> Products categories </ScrollLink>
       
//         {/*FOOD PACKAGING AND PASTERY MATERIALS, CARS SPARE PARTS AND ACCESSORIES, MEDICAL PRODUCTS, AGRICULTURAL PROUDCTS, construction materials*/}
//         {/*ADD A LITTLE SECTION UNDER IT FOR AGRICUTURE*/}

//         <ScrollLink to="ArzagAgriculture" smooth={true} duration={500} className="text-lg font-bold smoothscroll hover:text-gray-500 cursor-pointer"> Agricultural trading </ScrollLink>
       
//         {/* LCL, FCL, CARGO THE GOODS STORED IN OUR WAREHOUSES CAN BE SHIPPED */}
//         <p className='text-lg font-bold  hover:text-gray-500 hover:cursor-pointer'>Contact Us</p>
//     </div>
//   );
// };





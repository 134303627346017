import React from 'react';

const Hero = () => {
  return (
    <div className='w-full h-screen'>
      <img
        className='top-0 left-0 w-full h-screen object-cover'
        src='https://images.unsplash.com/photo-1474181487882-5abf3f0ba6c2?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        // https://images.pexels.com/photos/2165688/pexels-photo-2165688.jpeg
        alt='/'
      />
      <div className='bg-black/20 absolute top-0 left-0 w-full h-screen' />
      <div className='absolute top-0 w-full h-full flex flex-col justify-center text-white'>
        <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
          <p></p>
          <h1 className='font-bold text-5xl md:text-6xl drop-shadow-2xl'>
          Your One-stop trading <br/> partner in China
          </h1>
          <p className='max-w-[600px] drop-shadow-2xl py-2 text-xl'>
          Conduct business with foreign suppliers confidently. 
          </p>
          {/* <button className='bg-white text-black '>Get Help Now</button> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';

const Agriculture = () => {
  return (
    <div id='ArzagAgriculture' className='max-w-[1400px] h-[500px] bg-blue-100 mx-auto my-20 pt-16 lg:mb-[20%] md:mb-[35%] px-4 grid lg:grid-cols-3 gap-4'>
      <div className='lg:top-20 relative lg:col-span-1 col-span-2'>
        <h3 className="text-4xl md:text-5xl font-bold">Agriculture trading</h3>
        <p className='pt-4'>
        Discover high-quality peanuts and sesame from Sudan and Tanzania, offered by Arzag. With our extensive supplier network, we guarantee a reliable, large-scale supply for wholesalers at very competitive prices. Elevate your offerings with our fresh, premium products. </p>
      </div>

      <div className='grid grid-cols-2 col-span-2 gap-2'>
        <img
        className='object-cover w-full h-full'
          src='https://images.unsplash.com/photo-1628317321557-68729bee6644?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt='/'
        />
        <img
        className='row-span-2 object-cover w-full h-full'
          src='https://images.unsplash.com/photo-1524594345772-c953a3ae53e8?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt='/'
        />
        <img
        className='object-cover w-full h-full'
          src='https://images.unsplash.com/photo-1535379453347-1ffd615e2e08?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aGVpZ2h0JTNBJTIwMTQ4MSUyMHB4JTIwd2lkdGglM0ElMjAxOTc0JTIwcHglMjBhZ3JpY3VsdHVyZSUyMGltYWdlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60'
          alt='/'
        />
      </div>
    </div>
  );
};

export default Agriculture;
